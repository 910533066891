import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);

import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
Vue.component("vue-cookie-accept-decline", VueCookieAcceptDecline);

import VueGtag from "vue-gtag";
import router from "./router";
Vue.config.productionTip = false;

let accept_cookies = false;
if (localStorage["vue-cookie-accept-decline-consent"] === "accept") {
  accept_cookies = true;
}
Vue.use(
  VueGtag,
  {
    config: { id: "UA-151187904-3" },
    bootstrap: accept_cookies,
  },
  router
);

new Vue({
  el: "#app",
  vuetify,
  router,
  render: h => h(App),
}).$mount("#app");
