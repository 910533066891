<template>
  <!-- WATCHTIME -->
  <div class="hero">
    <v-row class="justify-center">
      <v-col class="mb-5">
        <h1 class="hero-text">your time wasted on Anime:</h1>
        <v-row class="justify-center mt-4">
          <v-col cols="auto">
            <span class="watchtime-text">
              <span v-if="animatedDays < 10">0</span>
              {{ animatedDays }} :<br />
            </span>
            <span v-if="days > 1 || days === 0">
              <p class="date-text">days</p>
            </span>
            <span v-if="days === 1">
              <p class="date-text">day</p>
            </span>
          </v-col>
          <v-col cols="auto">
            <span class="watchtime-text">
              <span v-if="animatedHours < 10">0</span>
              {{ animatedHours }} :<br />
            </span>
            <span v-if="hours > 1 || hours == 0">
              <p class="date-text">hours</p>
            </span>
            <span v-if="hours == 1">
              <p class="date-text">hour</p>
            </span>
          </v-col>
          <v-col cols="auto">
            <span class="watchtime-text">
              <span v-if="animatedMinutes < 10">0</span>
              {{ animatedMinutes }}<br />
            </span>
            <span v-if="minutes > 1 || minutes == 0">
              <p class="date-text">minutes</p>
            </span>
            <span v-if="minutes == 1">
              <p class="date-text">minute</p>
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import gsap from "gsap";

export default {
  name: "Hero",

  props: { days: Number, hours: Number, minutes: Number },
  data() {
    return {
      tweenedNumberDays: 0,
      tweenedNumberHours: 0,
      tweenedNumberMinutes: 0,
    };
  },
  computed: {
    animatedDays: function () {
      return this.tweenedNumberDays.toFixed(0);
    },
    animatedHours: function () {
      return this.tweenedNumberHours.toFixed(0);
    },
    animatedMinutes: function () {
      return this.tweenedNumberMinutes.toFixed(0);
    },
  },
  watch: {
    days(newValue) {
      gsap.to(this.$data, { duration: 0.5, tweenedNumberDays: newValue });
    },
    hours(newValue) {
      console.log(newValue);
      gsap.to(this.$data, { duration: 1.0, tweenedNumberHours: newValue });
    },
    minutes(newValue) {
      gsap.to(this.$data, { duration: 1.5, tweenedNumberMinutes: newValue });
    },
  },
};
</script>

<style scoped>
.hero-text {
  font-size: 1rem;
  color: azure;
}

.watchtime-text {
  font-size: 2rem;
  color: azure;
}

.date-text {
  font-size: 1rem;
}

.hero {
  margin-top: 180px;
}

@media (min-width: 768px) {
  .date-text {
    font-size: 1.3rem;
  }
  .watchtime-text {
    font-size: 4.7rem;
  }
}
</style>
