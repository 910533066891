<template>
  <div class="header">
    <v-row>
      <v-col cols="9" class="text-left">
        <router-link
          style="text-decoration: none; color: white"
          to="/impressum"
        >
          Imprint & Privacy Policy
        </router-link>
        <SocialShareButtons :totalWatchtime="totalWatchtime" />
      </v-col>
      <v-col cols="3" class="text-right">
        <v-icon color="grey" dark @click.stop="dialog = true" x-large>
          mdi-information-outline
        </v-icon>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="395">
      <v-card>
        <v-card-title>
          <h2>what is how much time wasted?</h2>
        </v-card-title>
        <v-divider class="mb-4 horizontal"></v-divider>
        <v-card-text>
          <p>
            I bet everyone has wondered 'how much time have I actually wasted
            watching Anime?'
          </p>
          <p>calculate your total time spent watching animes now!</p>
          <h3>how it works</h3>
          <p>
            <strong>•</strong> number of seasons <strong>x</strong> number of
            episodes per season <strong>x</strong> runtime of episode = total
            for 1 anime show. repeat for more anime shows = total time.
          </p>
          <p>
            <strong>•</strong> Your anime-list will be saved in your
            local-storage, even if you leave the page.
          </p>
          <a class="mb-5 link" @click="resetLocalStorage()">Reset all data</a>

          <p>
            a project by
            <a class="link" href="https://bias-int.com/en/">bias-int.com</a>
          </p>
          <p>
            do you have any questions or feature requests? Text us:
            <a class="link" href="mailto:info@bias-int.com"
              >info@bias-int.com</a
            >
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SocialShareButtons from "./SocialShareButtons";

export default {
  name: "Header",

  components: {
    SocialShareButtons,
  },
  props: {
    totalWatchtime: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.header {
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 20px 50px 0px 30px;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  margin-bottom: 10px;
}

.v-card__text {
  font-size: 1rem;
}

.link {
  color: red;
  font-weight: bold;
  display: block;
}
</style>
