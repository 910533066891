<template>
  <v-row justify="start" class="mt-3">
    <ShareNetwork
      class="ml-3"
      network="facebook"
      :url="url"
      :title="title"
      description=""
      :quote="
        'I spent a total time of ' +
          totalWatchtime +
          ' into watching anime! Help me please!'
      "
      hashtags="howmuchtimewasted"
    >
      <img :src="require('../logos/facebook_icon.png')" height="25" />
    </ShareNetwork>

    <ShareNetwork
      class="ml-3"
      network="twitter"
      :url="url"
      :title="title"
      :description="
        'I spent a total time of ' +
          totalWatchtime +
          ' into watching anime! Help me please!'
      "
      hashtags="biasinteractive,howmuchtimewasted,anime"
    >
      <img :src="require('../logos/twitter_icon.png')" height="25" />
    </ShareNetwork>

    <ShareNetwork
      class="ml-3"
      network="whatsapp"
      :url="url"
      :title="title"
      :description="
        'I spent a total time of ' +
          totalWatchtime +
          ' into watching anime! Help me please!'
      "
    >
      <img :src="require('../logos/whatsapp_icon.png')" height="25" />
    </ShareNetwork>
  </v-row>
</template>

<script>

export default {
  name: "SocialShareButtons",
  props: {
    totalWatchtime: String,
  },
  data() {
    return {
      url: "https://howmuchtimewasted.com",
      title: "Find out how much time you wasted on anime ->",
    };
  },
};
</script>
