<template>
  <v-container fluid>
    <div
      style="background-image: url(https://image.tmdb.org/t/p/w500/3XlKckxPEa4lg5w4vHnyE35PUyI.jpg)"
      class="background-image"
    ></div>
    <v-row justify="center" class="mt-12">
      <v-btn
        ><router-link to="/" style="text-decoration: none; color: black"
          >Back to HowMuchTimeWasted</router-link
        ></v-btn
      >
    </v-row>
    <v-row justify="center" class="mt-12">
      <v-col cols="12">
        <v-card color="rgb(255, 255, 255, 0.8)">
          <v-card-text class="text-left imprint-font">
            <v-row justify="center">
              <v-col cols="11" justify="center" class="mt-10">
                <h1>Privacy Policy</h1>
                <br />

                Responsible person in the sense of the data protection laws, in
                particular the EU General Data Protection Regulation (DSGVO),
                is: bias Interactive GmbH<br />
                <br />
                <h2>Your data subject rights</h2>

                <br />
                <p>
                  Using the contact details provided for our Data Protection
                  Officer, you can exercise the following rights at any time
                  exercise:
                </p>
                <ul>
                  <li>
                    Information about your data stored by us and their
                    Processing (Art. 15 DSGVO),
                  </li>
                  <li>
                    Correction of incorrect personal data (Art. 16 DSGVO),
                  </li>
                  <li>
                    deletion of your data stored by us (Art. 17 DSGVO),
                  </li>
                  <li>
                    Restriction of data processing, if we are not yet allowed to
                    delete your data due to legal obligations may not yet delete
                    (Art. 18 DSGVO),
                  </li>
                  <li>
                    Objection to the processing of your data by us (Art. 21
                    DSGVO) and
                  </li>
                  <li>
                    Data portability, provided that you have consented to the
                    data processing have consented or have concluded a contract
                    with us have concluded (Art. 20 DSGVO).
                  </li>
                </ul>
                <p>
                  Provided that you have given us consent, you can revoke it at
                  any time with effect for the future.
                </p>
                <p>
                  You may at any time lodge a complaint with a supervisory
                  authority, e.g. to the responsible supervisory supervisory
                  authority of the federal state of your residence or to the
                  authority responsible for us as the responsible body.
                </p>
                <p>
                  A list of the supervisory authorities (for the non-public
                  Area) with address can be found at:
                  <a
                    href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html"
                    target="_blank"
                    rel="noopener nofollow"
                    >https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a
                  >
                </p>
                <p></p>
                <h2>
                  Collection of general information when visiting our Website
                </h2>
                <br />

                <h3>Type and purpose of processing:</h3>
                <p>
                  When you access our website, i.e., when you do not do not
                  register or otherwise submit information transmit information,
                  information of a general nature is automatically nature is
                  collected. This information (server log files) includes, for
                  example, the type of web browser, the operating operating
                  system, the domain name of your Internet service provider,
                  your IP address and the like.
                </p>
                <p>
                  In particular, they are processed for the following purposes:
                </p>
                <ul>
                  <li>
                    Ensuring a problem-free connection setup of the website,
                  </li>
                  <li>
                    Ensuring a smooth use of our website,
                  </li>
                  <li>
                    Evaluating system security and stability, and
                  </li>
                  <li>to optimize our website.</li>
                </ul>
                <p>
                  We do not use your data to draw conclusions about your Person
                  to draw. Information of this kind are analyzed by us ggfs.
                  anonymously statistically evaluated, in order to improve our
                  Internet presence and the technology behind it to optimize
                  optimize.
                </p>
                <h3>Legal basis and legitimate interest:</h3>
                <p>
                  The processing is carried out in accordance with Art. 6 para.
                  1 lit. f DSGVO on the basis of our legitimate interest in
                  Improving the stability and functionality of our Website.
                </p>
                <h3>Receiver:</h3>
                <p>
                  Recipients of the data may be technical service providers who
                  are for the operation and maintenance of our website as
                  Processors active for the operation and maintenance of our
                  website.
                </p>
                <p></p>
                <h3>Storage period:</h3>
                <p>
                  The data are deleted as soon as they are no longer necessary
                  for the purpose of the Collection are no longer necessary.
                  This is the case for the Data that serve the provision of the
                  website, basically the case when the respective session is
                  ended. is.
                </p>
                <p></p>
                <h3>Provision prescribed or required:</h3>
                <p>
                  The provision of the aforementioned personal data is neither
                  required by law nor by contract. Without the IP address,
                  however, the service and functionality of our website is not
                  guaranteed. In addition, individual services and services may
                  not be available or be restricted. For this reason, an
                  contradiction excluded.
                </p>
                <p></p>
                <h2>Cookies</h2>
                <br />
                <p>
                  Like many other websites, we also use so-called "cookies."
                  Cookies are small text files that are stored on your end
                  device (laptop, tablet, Smartphone or similar) are stored when
                  you visit our Website visit.
                </p>
                <p>
                  You can delete individual cookies or the entire Delete cookie
                  inventory. In addition, you will receive information and
                  instructions on how to delete these cookies or their storage
                  can be blocked in advance. Depending on the provider of your
                  browser, you will find the necessary Information under the
                  following links:
                </p>
                <ul>
                  <li>
                    Mozilla Firefox:
                    <a
                      href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen"
                      target="_blank"
                      rel="nofollow noopener"
                      >https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a
                    >
                  </li>
                  <li>
                    Internet Explorer:
                    <a
                      href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
                      target="_blank"
                      rel="nofollow noopener"
                      >https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a
                    >
                  </li>
                  <li>
                    Google Chrome:
                    <a
                      href="https://support.google.com/accounts/answer/61416?hl=de"
                      target="_blank"
                      rel="nofollow noopener"
                      >https://support.google.com/accounts/answer/61416?hl=de</a
                    >
                  </li>
                  <li>
                    Opera:
                    <a
                      href="http://www.opera.com/de/help"
                      target="_blank"
                      rel="nofollow noopener"
                      >http://www.opera.com/de/help</a
                    >
                  </li>
                  <li>
                    Safari:
                    <a
                      href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE"
                      target="_blank"
                      rel="nofollow noopener"
                      >https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE</a
                    >
                  </li>
                </ul>
                <br />
                <h3>Duration of storage and cookies used:</h3>
                <p>
                  Insofar as you allow us through your browser settings or
                  Consent allow the use of cookies, can following cookies are
                  used on our websites:
                </p>
                <p>Google Analytics</p>
                <h2>Technically necessary cookies</h2>

                <br />
                <h3>Type and purpose of processing:</h3>
                <p>
                  We use cookies to make our website more user friendly. Some
                  elements of our website require that the calling browser can
                  be identified even after a Page change can be identified.
                </p>
                <p>
                  The purpose of using technically necessary cookies is, to
                  simplify the use of websites for users. Some functions of our
                  website can not be offered without the Use of cookies can not
                  be offered. For these it is that the browser is recognized
                  even after a page change. is recognized again.
                </p>
                <p>We require cookies for the following applications:</p>
                <p>Recognition of search terms</p>

                <h3>Legal basis and legitimate interest:</h3>

                <p>
                  The processing is carried out in accordance with Art. 6 para.
                  1 lit. f DSGVO. on the basis of our legitimate interest in a
                  user-friendly design of our website.
                </p>
                <h3>Receiver:</h3>
                <p>
                  Recipients of the data may be technical service providers who
                  are for the operation and maintenance of our website as
                  Processors active for the operation and maintenance of our
                  website.
                </p>
                <p></p>
                <h3>Provision mandatory or required:</h3>
                <p>
                  The provision of the aforementioned personal data is neither
                  required by law nor by contract. Without these data, however,
                  the service and the functionality of of our website is not
                  guaranteed. In addition, individual services may not be
                  available or may be limited. be.
                </p>
                <h3>Contradiction</h3>
                <p>
                  Please read the information about your right of objection
                  according to Art. 21 DSGVO below.
                </p>
                <p></p>
                <h2>Technically unnecessary cookies</h2>

                <br />
                <p>
                  Furthermore, we use cookies to better tailor the offer on our
                  website better to the interests of our visitors or on the
                  basis of statistical evaluations to improve generally to
                  improve.
                </p>
                <p>
                  Which providers set cookies, please refer to the information
                  listed below on the display, tracking, remarketing and
                  display, tracking, remarketing and web analysis Web analysis
                  technologies.
                </p>
                <h3>Legal basis:</h3>
                <p>
                  The legal basis for these processing operations is in each
                  case your Consent, Art. 6 para. 1 lit. a DSGVO.
                </p>
                <h3>Recipient:</h3>
                <p>
                  Recipients of the data are, where applicable, technical
                  service providers that are for the operation and maintenance
                  of our website as Processors active for the operation and
                  maintenance of our website.
                </p>
                <p>
                  For further recipients, please refer to the below listed
                  Information on the display, tracking, Remarketing and web
                  analytics technologies used.
                </p>
                <h3>Third Party Transfer:</h3>
                <p>
                  For information on this, please refer to the Listings of the
                  individual presentation, tracking, Remarketing and web
                  analytics providers.
                </p>
                <h3>Provision mandated or required:</h3>
                <p>
                  Of course, you can also view our website in principle without
                  View cookies. Web browsers are regularly set in such a way
                  that they accept cookies. In general you can deactivate the
                  use of cookies at any time deactivate the use of cookies at
                  any time via your browser settings (see Revoking of consent).
                </p>
                <p>
                  Please note that individual functions of our website may not
                  function if you have deactivated the use of cookies have been
                  deactivated.
                </p>
                <h3>Revocation of consent:</h3>
                <p>
                  You can withdraw your consent at any time via our Cookie
                  Consent Tool.
                </p>
                <h3>Profiling:</h3>
                <p>
                  To what extent we analyze the behavior of website visitors
                  with analyze the behavior of website visitors with
                  pseudonymized user profiles, you can please refer to the
                  information listed below on the display, tracking, remarketing
                  and web analytics technologies used. Web analysis
                  technologies.
                </p>
                <p></p>
                <h2>Use of Google Analytics</h2>
                <br />
                <p>
                  Insofar as you have given your consent, this website will use
                  website uses Google Analytics, a web analytics service of
                  Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA.
                  94043 USA (hereinafter: "Google"). Google Analytics uses
                  "cookies", which are text files placed on your computer, to
                  help the website computer and which enable an analysis of your
                  use of the website. use of the website by you. The information
                  generated by the information generated by the cookie about
                  your use of this website is usually transferred to a Google
                  server in the USA and stored there. the USA and stored there.
                  Due to the activation of the IP anonymization on these
                  websites, your However, your IP address will be stored by
                  Google within Member States of the European Union or in other
                  States party to the Agreement on the European Economic
                  Economic Area will be shortened beforehand. Only in
                  exceptional cases will the full IP address will be transmitted
                  to a Google server in the USA and and shortened there. The IP
                  address transmitted by your transmitted by your browser as
                  part of Google Analytics will not be not merged with other
                  data from Google.
                </p>
                <p>
                  For more information on terms of use and privacy can be found
                  at
                  <a
                    href="https://policies.google.com/privacy?hl=en-US"
                    rel="noopener"
                    target="_blank"
                    >https://www.google.com/analytics/terms/de.html and at
                    https://policies.google.com/?hl=de</a
                  >
                </p>
                <p>
                  On behalf of the operator of this website, Google will use
                  this information to evaluate your use of the website evaluate
                  your use of the website, compiling reports on website website
                  activities and to provide other services relating to website
                  services related to the use of the website and the internet
                  the website operator.
                </p>
                <p>
                  The data sent by us and linked to cookies, user IDs (e. (e.g.
                  user ID) or advertising IDs are automatically deleted after 14
                  months automatically deleted. The deletion of data whose
                  retention period is reached, takes place automatically once a
                  every month.
                </p>
                <h3>Revocation of consent:</h3>
                <br />
                <p>
                  You can stop tracking by Google Analytics on our Website by
                  clicking
                  <a
                    title="Set Google Analytics Opt-Out Cookie"
                    @click="deleteCookies()"
                    href="#"
                    >click this link</a
                  >. This will install an opt-out cookie on your device. This
                  will prevent the collection of data by Google Analytics for
                  this website and for this browser in the future browser as
                  long as the cookie remains installed in your browser. remains.
                </p>
                <p>
                  In addition, you can prevent the storage of cookies by a
                  corresponding setting of your browser software However, we
                  point out that in this case you may not be able to use all
                  that in this case you may not be able to use all features of
                  this Website will be able to use to the full extent.
                </p>
                <p>
                  You can also block the collection of the data generated by the
                  cookie generated by the cookie and related to your use of the
                  website (including your IP address) to Google and the
                  processing of this data by processing of this data by Google,
                  by downloading the browser plugin downloading the browser
                  plugin available at the following link and install it:
                  <a
                    href="http://tools.google.com/dlpage/gaoptout?hl=de"
                    rel="noopener"
                    target="_blank"
                    >Browser Add On to disable Google Analytics</a
                  >
                </p>
                <p></p>
                <h2>Use of script libraries (Google Web Fonts)</h2>

                <br />
                <p>
                  In order to display our content correctly and in a graphically
                  graphically appealing, we use on this website "Google Web
                  Fonts" by Google LLC (1600 Amphitheatre Parkway, Mountain
                  View, CA 94043, USA; hereinafter "Google"). for the display of
                  fonts.
                </p>
                <p>
                  For more information on Google Web Fonts, please visit.
                  <a
                    href="https://developers.google.com/fonts/faq"
                    rel="noopener nofollow"
                    target="_blank"
                    >https://developers.google.com/fonts/faq</a
                  >
                  And in Google's privacy policy:
                  <a
                    href="https://www.google.com/policies/privacy/"
                    rel="noopener nofollow"
                    target="_blank"
                    >https://www.google.com/policies/privacy/</a
                  >
                </p>
                <p></p>
                <h2>Social Plugins</h2>
                <br />
                <h3>Type and purpose of processing:</h3>
                <p>
                  We offer you on our website the possibility of Use of
                  so-called "social media buttons". To protect your data, we
                  rely on the implementation of the "Shariff" solution. This
                  means that these buttons are only integrated website only as a
                  graphic, which provides a link to the link to the
                  corresponding website of the button provider. By clicking on
                  the graphic to the services of the respective provider.
                  forwarded. Only then will your data be sent to the sent to the
                  respective provider. If you do not click on the graphic click
                  on the graphic, no exchange takes place between you and the
                  providers of the social media buttons. Information about the
                  collection and use of your data in the social media networks
                  can be found in the respective terms of use of the respective
                  providers. More information about the Shariff solution can be
                  found here:
                  <a
                    href="http://www.heise.de/ct/artikel/Shariff-Social-Media-Buttons-mit-Datenschutz-2467514.html"
                    >http://www.heise.de/ct/artikel/Shariff-Social-Media-Buttons-mit-Datenschutz-2467514.html</a
                  >
                </p>
                <p>
                  We have integrated on our website the social media buttons of
                  the following companies integrated:
                </p>
                <p>
                  Facebook (https://www.facebook.com) <br />Twitter
                  (https://www.twitter.com) <br />Whatsapp
                  (https://www.whatsapp.com/)
                </p>
                <p></p>
                <h2>SSL encryption</h2>
                <br />
                <p>
                  To protect the security of your data during transmission
                  transmission, we use state-of-the-art encryption corresponding
                  encryption methods (e.g. SSL) via HTTPS.
                </p>
                <p></p>

                <h2>
                  Information about your right to object according to Art. 21
                  DSGVO.
                </h2>
                <br />
                <h3>Single-case right of objection</h3>
                <p>
                  You have the right to object to the processing of your
                  personal data on grounds relating to your particular
                  situation, to object at any time to the Processing of personal
                  data relating to you, which is on the basis of Art. 6 para. 1
                  lit. f DSGVO (data processing on the on the basis of a
                  consideration of interests); this also applies to a This also
                  applies to profiling based on this provision. based profiling
                  within the meaning of Art. 4 No. 4 DSGVO.
                </p>
                <p>
                  If you lodge an objection, we will no longer process your
                  personal no longer process your personal data, unless we can
                  justify compelling reasons for the processing that are worthy
                  of protection which override your interests, rights and
                  freedoms, or the processing serves to Override, or the
                  processing serves the assertion, Exercise or defense of legal
                  claims.
                </p>
                <h3>Recipient of an opposition</h3>
                <p>
                  bias Interactive GmbH <br />Karl-Benz-Strasse 9 <br />40764
                  Langenfeld
                  <br />
                  <br />Phone: +49 211 538 050 31 <br />E-mail:
                  info@bias-int.com
                </p>

                <h2>Change to our privacy policy</h2>
                <br />
                <p>
                  We reserve the right to adjust this privacy policy, so that it
                  always corresponds to the current legal requirements or in
                  order to implement changes to our services in the privacy
                  policy, e.g. when introducing new services. of new services.
                  The new privacy policy will then apply to your next visit.
                  Privacy Policy will then apply to your next visit.
                </p>
                <h2>Questions to the data protection officer</h2>

                <br />
                <p>
                  If you have any questions about data protection, please write
                  to us an e-mail or contact the person responsible for data
                  protection in our organization directly Data Protection
                  Officer in our organization:
                </p>
                <p>Ron Makila</p>
                <br />
                <h2>
                  Imprint
                </h2>
                <br />
                <p>
                  bias Interactive GmbH <br />
                  Karl-Benz-Strasse 9<br />
                  40764 Langenfeld<br />
                  Contact: <br />
                  Phone: +49 211 538 050 31<br />
                  E-Mail:info@bias-int.com<br />
                  Authorized representative managing directors: Louis Rose &
                  Sakander Zirai<br />
                  Registration number: HRB 87747 Registration court: AG
                  Düsseldorf<br />
                  Sales tax identification number according to § 27a<br />
                  Umsatzsteuergesetz: DE326142118
                </p>
                <p></p>
                <p>
                  <em
                    >This privacy policy was created with the help of activeMind
                    AG, the experts in
                    <a
                      href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
                      target="_blank"
                      rel="noopener"
                      >external data protection officers</a
                    >
                    (version #2020-09-30).</em
                  >
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-12 mb-12">
      <v-btn
        ><router-link to="/" style="text-decoration: none; color: black"
          >Back to HowMuchTimeWasted</router-link
        ></v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  name: "Impressum",
  methods: {
    deleteCookies() {
      Vue.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
      localStorage.removeItem("vue-cookie-accept-decline-consent");
      alert("deleted cookies");
    },
  },
};
</script>

<style scoped>
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-color: rgb(153, 252, 255);
  filter: blur(5px) brightness(0.4);
}
.imprint-font {
  font-family: "Arial";
}
</style>
