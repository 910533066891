<template>
  <v-app>
    <router-view />
    <vue-cookie-accept-decline
      :ref="'consent'"
      :elementId="'consent'"
      :debug="false"
      :position="'bottom-right'"
      :type="'floating'"
      :disableDecline="false"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
      @status="cookieStatus"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
    >
      <!-- Optional -->
      <div slot="message">
        We use cookies to ensure you get the best experience on our website.
        <router-link style="text-decoration: none; color: black" to="/impressum"
          >Learn More ..
        </router-link>
      </div>

      <!-- Optional -->
      <div slot="declineContent">
        Decline
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
        Accept
      </div>
    </vue-cookie-accept-decline>
  </v-app>
</template>

<script>
import { bootstrap } from "vue-gtag";

import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// set default config
Vue.$cookies.config("7d");

export default {
  name: "App",
  components: {},
  data() {
    return { bimage: "", status: null };
  },
  methods: {
    cookieStatus(status) {
      this.status = status;
    },
    cookieClickedAccept() {
      this.status = "accept";
      bootstrap().then(() => {
        console.log("gtag enabled");
      });
    },

    cookieClickedDecline() {
      console.log("Cookies removed");
      this.status = "decline";
      Vue.$cookies.remove("_ga"); // return this
      Vue.$cookies.remove("_gid"); // return this
    },

    removeCookie() {
      console.log("Cookies removed");
      this.$refs.consent.removeCookie();
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Animeee";
  src: local("Animeee"), url(./fonts/Animeee.otf) format("truetype");
}

html,
body {
  overflow-x: hidden;
  background-color: black;
}

#app {
  font-family: Animeee;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
/* cookie banner*/

.cookie__floating__content {
  color: black;
}

.v-image__image--preload {
  filter: blur(0) !important;
}
</style>
