<template>
  <div>
    <!-- ADD POSTER -->
    <v-row v-if="animeStorage.length">
      <!-- <transition-group name="fade-in" mode="out-in"> -->
      <v-col
        cols="4"
        sm="auto"
        v-for="(value, i) in animeStorage"
        v-bind:key="i"
      >
        <v-card class="postershadow" max-width="180px">
          <v-img
            class="poster-image"
            :src="thumbnail + value.poster_path"
            :lazy-src="require('../logos/relaxo.jpg')"
          />
          <!-- DELETE POSTER (ALSO FROM STORAGE) -->
          <v-card-actions class="btn-delete-action postershadow">
            <v-btn
              md4
              block
              class="red white--text"
              @click="$emit('deleteAnime', i)"
            >
              DELETE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- </transition-group> -->
    </v-row>
    <v-row v-else class="mt-md-16 pt-md-16"
      ><v-col><h2 class="white--text">looks pretty empty ...</h2></v-col></v-row
    >
  </div>
</template>

<script>
export default {
  name: "SelectedAnimes",
  props: {
    animeStorage: Array,
    watchtimeStorage: Array,
  },
  data() {
    return {
      // series/movie animeStorage //
      thumbnail: "https://image.tmdb.org/t/p/w342",
    };
  },
};
</script>

<style scoped>
.postershadow {
  box-shadow: 0 0px 5px 0px rgba(255, 197, 111, 0.7),
    0px 0px 12px 0px rgba(255, 255, 255, 0.9) !important;
  background-image: black;
}

.poster-image {
  height: 100px;
}

.btn-delete-action {
  padding: 1px;
  background-color: red;
}

@media (min-width: 768px) {
  .poster-image {
    height: 275px;
  }
}
</style>
