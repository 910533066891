<template>
  <v-row justify="center" align="center">
    <v-col cols="10" md="12">
      <!-- INPUT FIELD SEARCH -->
      <v-text-field
        outlined
        filled
        rounded
        dark
        clearable
        hide-details
        autocomplete="off"
        class="search-input"
        v-model="animeSearchField"
        :placeholder="fromTimeWindow"
        @input="onSearch"
      >
        ></v-text-field
      >
    </v-col>

    <!-- TYPING ASSISTANCE -->

    <v-row
      justify="center"
      align="center"
      class="text--secondary text-left answer-field"
      v-if="answer"
    >
      <v-col cols="10" md="12">
        <p class="pl-8 answer-field">{{ answer }}</p>
      </v-col>
    </v-row>

    <!-- RESULTS TO CHOOSE FROM -->

    <v-col
      cols="10"
      md="12"
      class="mt-2"
      v-for="(value, i) in apiResult"
      v-bind:key="i"
    >
      <v-row
        class="searchResults pl-8"
        v-on:click="getMovieData(value)"
        not-gutters
      >
        <span>{{ value.name | truncate(23) }}</span>
      </v-row>
    </v-col>

    <!-- INPUT FIELD SEASON -->

    <v-col cols="12" v-if="seasons">
      how many seasons did you watch?
      <v-slider
        v-model="seasons"
        :max="detailedMovieData.number_of_seasons"
        :min="1"
        thumb-color="#808080"
        color="orange"
        track-color="grey"
        thumb-label="always"
        hide-details
      >
      </v-slider>
      <!-- <v-text-field
        outlined
        filled
        rounded
        dark
        clearable
        hide-details
        type="number"
        autocomplete="off"
        class="search-input textfield02"
        v-model="seasons"
        placeholder="how many seasons did you watch?"
      ></v-text-field> -->
    </v-col>

    <!-- ADD SERIES/MOVIE -->
    <v-col class="text-right pr-4" cols="12">
      <div class="mt-5">
        <v-btn
          outlined
          rounded
          clearable
          height="48"
          class="orange white--text"
          v-if="generalMovieData"
          v-on:click="addToStorage()"
        >
          ADD ANIME TO LIST
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "Search",
  data() {
    return {
      animeSearchField: "",
      seasons: 0,
      apiResult: [],
      fromTimeWindow: "search for anime",
      answer: "please enter at least 3 characters",
      detailedMovieData: [],
      generalMovieData: null,
    };
  },
  filters: {
    truncate: function (value, limit) {
      if (value.length > limit) {
        return value.substring(0, limit) + " ... ";
      } else {
        return value;
      }
    },
  },
  methods: {
    onSearch(input) {
      if (input.length < 3) {
        this.answer = "please enter at least 3 characters";
      } else {
        this.getAnswer();
      }
    },
    getAnswer() {
      this.apiResult = [];

      axios
        .get(
          "https://api.themoviedb.org/3/search/tv?api_key=c13a406bc701f0f32b79f3ec5f3b2675&language=en-US&page=1&query=" +
            this.animeSearchField +
            "&include_adult=true"
        )
        .then((response) => {
          if (response.data.results.length) {
            this.answer = null;
            for (var i = 0; i < response.data.results.length; i++) {
              if (
                response.data.results[i].original_language == "ja" &&
                response.data.results[i].origin_country[0] == "JP" &&
                this.isAnimeFilter(response.data.results[i])
              ) {
                this.apiResult.push(response.data.results[i]);
              }
            }
          } else {
            this.apiResult = [];
            this.answer = "no animes found...";
          }
        });
    },
    getMovieData(value) {
      this.animeSearchField = value.name; // result shows up in input field
      this.apiResult = [];
      this.generalMovieData = value;
      this.answer = "";
      axios
        .get(
          "https://api.themoviedb.org/3/tv/" +
            this.generalMovieData.id +
            "?api_key=c13a406bc701f0f32b79f3ec5f3b2675&language=en-US"
        )
        .then((response) => {
          //  save whole data - use later
          this.detailedMovieData = response.data;
          //  save input for season
          this.seasons = response.data.number_of_seasons;
        })
        .catch((error) => {
          this.answer = "Error! Could not reach the API. " + error;
        });
    },
    isAnimeFilter(response) {
      for (var i = 0; i < response.genre_ids.length; i++) {
        if (response.genre_ids[i] == 16) {
          return true;
        }
      }
      return false;
    },
    averageNumberOfEpisodes() {
      return (
        this.detailedMovieData.number_of_episodes /
        this.detailedMovieData.number_of_seasons
      );
    },
    addToStorage() {
      let tmpTime = 0;
      if (this.detailedMovieData.episode_run_time.length > 0) {
        tmpTime =
          this.detailedMovieData.episode_run_time[0] *
          this.averageNumberOfEpisodes() *
          this.seasons;
      }

      this.$emit("newAnime", { data: this.generalMovieData, time: tmpTime });
      // setting variables to default after adding data
      this.generalMovieData = null;
      this.seasons = 0;
      this.animeSearchField = "";
      this.apiResult = [];
    },
  },
};
</script>

<style scoped>
.answer-field {
  font-size: 1rem;
  color: azure;
}

.searchResults {
  cursor: pointer;
  font-size: 1rem;
  color: azure;
}

.searchResults:hover {
  color: orange;
}

@media (min-width: 768px) {
  .answer-field {
    font-size: 1rem;
  }

  .searchResults {
    font-size: 1.8rem;
  }
}
</style>
