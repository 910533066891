<template>
  <v-main>
    <div
      v-if="backgroundImage"
      :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
      class="background-image"
    ></div>
    <v-container fluid>
      <Header :totalWatchtime="totalWatchtime" />
      <div style="position: relative">
        <Hero :days="days" :hours="hours" :minutes="minutes" />
        <v-row class="mt-5">
          <v-col cols="12" md="6" class="mt-3">
            <Search @newAnime="addAnimeToStorage($event)" />
          </v-col>
          <v-divider class="d-none d-md-block" vertical></v-divider>
          <v-col cols="12" md="6">
            <SelectedAnimes
              :animeStorage="animeStorage"
              :watchtimeStorage="watchtimeStorage"
              @deleteAnime="deleteAnime($event)"
            />
          </v-col>
        </v-row>
        <!-- <Overview @changeBackgroundImage="changeBackgroundImage" /> -->
      </div>
    </v-container>
  </v-main>
</template>

<script>
import Header from "../components/Header";
import Hero from "../components/Hero";
import Search from "../components/Search";
import SelectedAnimes from "../components/SelectedAnimes";

// import Overview from "../components/Overview";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// set default config
Vue.$cookies.config("7d");

export default {
  name: "Anime",
  components: {
    Header,
    Hero,
    Search,
    SelectedAnimes,
    // Overview,
  },
  data() {
    return {
      backgroundImage:
        "https://image.tmdb.org/t/p/w500/3XlKckxPEa4lg5w4vHnyE35PUyI.jpg",
      backgroundImageRoot: "https://image.tmdb.org/t/p/w500",
      status: null,
      animeStorage: [],
      watchtimeStorage: [],
      totalWatchtime: "",
      days: 0,
      hours: 0,
      minutes: 0,
      tweenedNumberDays: 0,
      tweenedNumberHours: 0,
      tweenedNumberMinutes: 0,
    };
  },
  created() {
    if (localStorage.animeStorage) {
      this.animeStorage = JSON.parse(localStorage.animeStorage);
      if (this.animeStorage.length) {
        this.changeBackgroundImage(
          this.backgroundImageRoot + this.animeStorage.at(-1).backdrop_path
        );
      }
    }
  },
  mounted() {
    //  show saved watchtime  //
    if (localStorage.watchtimeStorage) {
      this.watchtimeStorage = JSON.parse(localStorage.watchtimeStorage);
      this.updateWatchtime();
    }
  },
  methods: {
    timeConvert(n) {
      var num = n;
      var d = Math.floor(num / 1440); // 60*24
      var h = Math.floor((num - d * 1440) / 60);
      var m = Math.round(num % 60);

      this.days = d;
      this.hours = h;
      this.minutes = m;

      if (d > 0) {
        return d + " DAY(S) | " + h + " HOURS | " + m + " MIN";
      } else {
        return h + " HOURS | " + m + " MIN";
      }
    },
    deleteAnime(index) {
      this.$delete(this.watchtimeStorage, index);
      this.$delete(this.animeStorage, index);
      if (
        this.animeStorage.length > 0 &&
        this.animeStorage.at(-1).backdrop_path
      ) {
        this.changeBackgroundImage(
          this.backgroundImageRoot + this.animeStorage.at(-1).backdrop_path
        );
      } else {
        this.changeBackgroundImage(
          "https://image.tmdb.org/t/p/w500/3XlKckxPEa4lg5w4vHnyE35PUyI.jpg"
        );
      }
      this.updateLocalStorage();
      this.updateWatchtime();
    },
    addAnimeToStorage(event) {
      this.animeStorage.push(event.data);
      this.watchtimeStorage.push(event.time);
      if (event.data.backdrop_path)
        this.changeBackgroundImage(
          this.backgroundImageRoot + event.data.backdrop_path
        );
      this.updateLocalStorage();
      this.updateWatchtime();
    },
    updateWatchtime() {
      this.totalWatchtime = "";
      let totalWatchtime = 0;
      for (var i = 0; i < this.watchtimeStorage.length; i++) {
        totalWatchtime = totalWatchtime + this.watchtimeStorage[i];
      }
      this.totalWatchtime = this.timeConvert(totalWatchtime);
    },
    updateLocalStorage() {
      localStorage.animeStorage = JSON.stringify(this.animeStorage);
      localStorage.watchtimeStorage = JSON.stringify(this.watchtimeStorage);
    },
    changeBackgroundImage(image) {
      const imageTmp = new Image();
      imageTmp.src = image;
      setTimeout(() => (this.backgroundImage = imageTmp.src), 500);
    },
  },
};
</script>

<style scoped>
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-color: grey;
  background-position: 50% 50%;
  filter: blur(5px) brightness(0.5);
  transition: background 0.7s linear;
}

.theme--light.v-divider {
  height: 26em;
  border-color: rgba(219, 219, 219, 0.493) !important;
  z-index: 1;
}

/* cookie banner*/

h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
